@font-face {
  font-family: 'Rubik';
  src: url('./assets/Fonts/Rubik-VariableFont_wght.ttf');
}

html,
body,
#root {
  height: 100%;
  background-color: #172942;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
